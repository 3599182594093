import './style.css';
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import FrameProps from '@/services/frameProps';
import GlobalGuard from '@/services/globalGuard';
import root from './App.vue';
import layout from './layout.html?raw';

GlobalGuard.handleInvalidAppStorage();
GlobalGuard.handleInvalidAuth();

const props = new FrameProps();

const data: any = {
  props: props.get(),
};

const routes = constructRoutes(layout, data);
/**
 * The constructApplications API transforms the routes into single-spa application registration objects.
 * These application registration objects are then used to call registerApplication().
 * The @vite-ignore is present because it currently throws a warning when dynamically importing
 */

const applications = constructApplications({
  routes,
  loadApp({ name }: any) {
    return import(/* @vite-ignore */ name);
  },
});

/**
 * The constructLayoutEngine API transforms your routes and applications into a layoutEngine object.
 * The layout engine is responsible for creating, destroying, and rearranging dom elements during route transitions.
 */
const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);
layoutEngine.activate();
start();

const app = createApp(root);

props.addStorageListener();

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: [import.meta.env.VITE_API_BASE_URL],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

app.mount('#app');
