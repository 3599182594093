<template>
  <svg class="esf-icon-sprite">
    <symbol id="fa-eventim-logo" viewBox="26.5 27.2 510.15 106.39">
      <path
        class="yellow"
        d="M499.55,64.2L499.55,64.2c6.2,6.2,6.4,15.9,6.4,22.7h1.6c0-6.7,0.2-16.5,6.4-22.7l0,0    c6.2-6.2,15.9-6.4,22.7-6.4v-1.5c-6.7,0-16.5-0.2-22.7-6.4l0,0c-6.2-6.2-6.4-15.9-6.4-22.7h-1.6c0,6.7-0.2,16.5-6.4,22.7l0,0    c-6.2,6.2-15.9,6.4-22.7,6.4v1.6C483.55,57.8,493.35,58,499.55,64.2z"
      />
      <g>
        <path
          class="blue"
          d="M50.58,77.07c14.82,0,23.18,12.04,23.18,28.76v3.79H39.99c0.56,7.36,4.9,13.15,13.04,13.15   c4.12,0,8.69-1.89,11.7-5.24l6.13,8.03c-4.79,5.24-12.04,8.03-19.28,8.03c-15.16,0-25.08-11.37-25.08-28.31   C26.5,89.66,35.64,77.07,50.58,77.07z M39.99,100.48h20.84c-0.22-5.57-2.68-12.6-10.48-12.6C42.89,87.88,40.21,94.9,39.99,100.48z"
        />
        <path class="blue" d="M71.53,78.41H85.8l10.81,37.79l10.92-37.79h14.27l-17.95,53.84H89.48L71.53,78.41z" />
        <path
          class="blue"
          d="M143.88,77.07c14.82,0,23.18,12.04,23.18,28.76v3.79h-33.77c0.56,7.36,4.9,13.15,13.04,13.15   c4.12,0,8.69-1.89,11.7-5.24l6.13,8.03c-4.79,5.24-12.04,8.03-19.28,8.03c-15.16,0-25.08-11.37-25.08-28.31   C119.8,89.66,128.94,77.07,143.88,77.07z M133.29,100.48h20.84c-0.22-5.57-2.68-12.6-10.48-12.6   C136.18,87.88,133.51,94.9,133.29,100.48z"
        />
        <path
          class="blue"
          d="M201.28,96.8c0-5.68-2.56-7.91-7.02-7.91s-7.91,3.46-9.81,6.69v36.67h-13.38V78.41h13.38v7.02   c2.9-4.24,8.92-8.36,15.83-8.36c9.92,0,14.38,5.8,14.38,14.71v40.46h-13.38V96.8z"
        />
        <path
          class="blue"
          d="M224.25,119.32v-29.2h-7.02v-11.7h7.02V63.69h13.38V78.4h8.92v11.7h-8.92v26.08c0,3.46,1.34,5.57,3.9,5.57   c1.67,0,3.34-0.67,4.01-1.56l2.79,10.25c-2.01,1.78-5.46,3.12-10.37,3.12C228.59,133.58,224.25,128.79,224.25,119.32z"
        />
        <path
          class="blue"
          d="M248.88,62.47c0-4.46,3.68-8.14,8.03-8.14c4.46,0,8.03,3.68,8.03,8.14s-3.57,8.03-8.03,8.03   C252.56,70.49,248.88,66.93,248.88,62.47z M250.22,78.41h13.38v53.84h-13.38V78.41z"
        />
        <path
          class="blue"
          d="M328.24,96.02c0-4.46-1.89-7.13-6.13-7.13s-7.58,3.68-9.25,6.69v36.67h-13.38V96.02   c0-4.35-1.78-7.13-6.13-7.13c-4.12,0-7.58,3.68-9.25,6.69v36.67h-13.38V78.41h13.38v7.02c2.12-3.68,8.36-8.36,15.16-8.36   c6.8,0,11.59,3.23,13.04,9.36c2.56-4.68,8.92-9.36,15.72-9.36c8.25,0,13.6,4.35,13.6,14.27v40.91h-13.38V96.02z"
        />
        <path
          class="yellow"
          d="M351.09,119.32v-29.2h-7.02v-11.7h7.02V63.69h13.38V78.4h8.92v11.7h-8.92v26.08c0,3.46,1.34,5.57,3.9,5.57   c1.67,0,3.34-0.67,4.01-1.56l2.79,10.25c-2.01,1.78-5.46,3.12-10.37,3.12C355.44,133.58,351.09,128.79,351.09,119.32z"
        />
        <path
          class="yellow"
          d="M375.73,62.47c0-4.46,3.68-8.14,8.03-8.14c4.46,0,8.03,3.68,8.03,8.14s-3.57,8.03-8.03,8.03   C379.41,70.49,375.73,66.93,375.73,62.47z M377.07,78.41h13.38v53.84h-13.38V78.41z"
        />
        <path
          class="yellow"
          d="M415.97,114.3l-9.7,17.95h-14.49l15.72-27.42l-14.94-26.42h14.6l8.81,16.94l8.69-16.94h14.6l-14.94,26.42   l15.83,27.42h-14.6L415.97,114.3z"
        />
        <path
          class="yellow"
          d="M464.35,114.3l-9.7,17.95h-14.49l15.72-27.42l-14.94-26.42h14.6l8.81,16.94l8.69-16.94h14.6l-14.94,26.42   l15.83,27.42h-14.6L464.35,114.3z"
        />
      </g>
    </symbol>
  </svg>
</template>

<style scoped>
.yellow {
  fill: #feca27;
}

.blue {
  fill: #202867;
}
</style>
