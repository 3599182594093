import { Token, Language } from '@eventim/tixx-component-library/models';
import { getBrowserLanguage } from '@eventim/tixx-component-library/utils';

export default class LanguageStorage {
  readonly languageKey: string = 'frame_language';

  saveFromToken(token: Token | string): Language {
    if (typeof token === 'string') {
      // eslint-disable-next-line no-param-reassign
      token = new Token(token);
    }

    const language = new Language({
      locale: token.session.locale,
      languageProfile: token.session.languageProfile,
    });

    sessionStorage.setItem(this.languageKey, JSON.stringify(language));

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.languageKey,
        newValue: JSON.stringify(language),
      }),
    );

    return language;
  }

  get(): Language {
    const language = sessionStorage.getItem(this.languageKey);

    if (language !== null) {
      return new Language(JSON.parse(language));
    }

    return getBrowserLanguage();
  }

  delete(): void {
    sessionStorage.removeItem(this.languageKey);
  }
}
