import { Token } from '@eventim/tixx-component-library/models';

export default class TokenStorage {
  readonly tokenKey: string = 'frame_token';

  save(token: Token | string): Token {
    if (typeof token === 'string') {
      // eslint-disable-next-line no-param-reassign
      token = new Token(token);
    }

    sessionStorage.setItem(this.tokenKey, token.raw);

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.tokenKey,
        newValue: token.raw,
      }),
    );

    return token;
  }

  get(): Token | null {
    const token = sessionStorage.getItem(this.tokenKey);

    if (token !== null) {
      return new Token(token);
    }

    return null;
  }

  delete(): void {
    sessionStorage.removeItem(this.tokenKey);
  }
}
