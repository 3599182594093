<template>
  <div class="esf-notifications">
    <div
      class="esf-notification esf-notification--multiple"
      :class="`esf-notification--${notification.type} is-active`"
      :key="notification.uniqueId"
      v-for="notification of notifications"
    >
      <div class="esf-container" v-html="notification.message"></div>
      <div
        class="esf-notification__close"
        @click="closeNotification(notification)"
        @keydown="closeNotification(notification)"
      >
        <svg class="esf-icon esf-icon--white" width="16" height="16">
          <use xlink:href="#esf-icon-close-16"></use>
        </svg>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Notification } from '@eventim/tixx-component-library/models';
import { getNotification } from '@Beavers/notification-service';
import { ref, Ref } from 'vue';

const notifications: Ref<Array<Notification>> = ref([]);

function closeNotification(notification: Notification) {
  const index = notifications.value.findIndex((obj) => obj.uniqueId === notification.uniqueId);
  notifications.value.splice(index, 1);
}

getNotification().subscribe((notification: Notification) => {
  notifications.value.unshift(notification);
  if (notification.duration) {
    window.setTimeout(() => closeNotification(notification), notification.duration);
  }
});
</script>
