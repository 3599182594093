<template>
  <template v-if="initialLoading">
    <div class="loading-splash" id="loading-splash">
      <div class="inner">
        <svg>
          <use href="#fa-eventim-logo"></use>
        </svg>

        <div class="esf-spinner" id="loading-spinner">
          <span class="esf-spinner__bounce esf-spinner__bounce--1"></span>
          <span class="esf-spinner__bounce esf-spinner__bounce--2"></span>
          <span class="esf-spinner__bounce esf-spinner__bounce--3"></span>
        </div>
      </div>
    </div>
  </template>
  <EdsIcons></EdsIcons>
  <FaIcons></FaIcons>
  <NotificationService></NotificationService>
</template>
<script setup lang="ts">
import NotificationService from '@/components/NotificationService.vue';
import FaIcons from '@/components/FaIcons.vue';
import { ref } from 'vue';
import { startGtag, startHotjar } from '@/services/initialScript';
import EdsIcons from './components/EdsIcons.vue';

const initialLoading = ref(true);

startGtag();
startHotjar();
setTimeout(() => {
  initialLoading.value = false;
}, 1500);
</script>

<style>
@media screen and (max-width: 480px) {
  body {
    padding-top: 2em;
  }
}
</style>
