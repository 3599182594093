export default class StorageVersionStorage {
  readonly storageVersionKey: string = 'frame_storageVersion';

  save(version: string): string {
    localStorage.setItem(this.storageVersionKey, version);

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.storageVersionKey,
        newValue: version,
      }),
    );

    return version;
  }

  get(): string | null {
    return localStorage.getItem(this.storageVersionKey);
  }

  delete(): void {
    localStorage.removeItem(this.storageVersionKey);
  }
}
