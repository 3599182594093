import TokenStorage from '@/shared/tokenStorage';
import AffiliateStorage from '@/shared/affiliateStorage';
import LoggedInAffiliateStorage from '@/shared/loggedInAffiliateStorage';
import LanguageStorage from '@/shared/languageStorage';
import { Affiliate, Language, Token } from '@eventim/tixx-component-library/models';
import { BehaviorSubject } from 'rxjs';
import * as routingTreeJson from '@/schema/routingTree.json';
import { RoutingTree } from '@eventim/tixx-component-library/schema';

interface PropsInterface {
  language: BehaviorSubject<Language>;
  token: BehaviorSubject<string | undefined>;
  affiliate: BehaviorSubject<Affiliate | null>;
  loggedInAffiliate: BehaviorSubject<Affiliate | null>;
  routingTree: BehaviorSubject<RoutingTree>;
}

export default class FrameProps {
  static readonly tokenStorage: TokenStorage = new TokenStorage();

  static readonly affiliateStorage: AffiliateStorage = new AffiliateStorage();

  static readonly loggedInAffiliateStorage: LoggedInAffiliateStorage = new LoggedInAffiliateStorage();

  static readonly languageStorage: LanguageStorage = new LanguageStorage();

  private readonly languageSubject: BehaviorSubject<Language>;

  private readonly tokenSubject: BehaviorSubject<string | undefined>;

  private readonly affiliateSubject: BehaviorSubject<Affiliate | null>;

  private readonly loggedInAffiliateSubject: BehaviorSubject<Affiliate | null>;

  private readonly routingTreeSubject: BehaviorSubject<RoutingTree>;

  constructor() {
    const affiliate = FrameProps.affiliateStorage.get();
    const loggedInAffiliate = FrameProps.loggedInAffiliateStorage.get();
    const token = FrameProps.tokenStorage.get();
    const language = FrameProps.languageStorage.get();

    this.languageSubject = new BehaviorSubject(language);
    this.tokenSubject = new BehaviorSubject(token?.raw);
    this.affiliateSubject = new BehaviorSubject(affiliate);
    this.loggedInAffiliateSubject = new BehaviorSubject(loggedInAffiliate);
    this.routingTreeSubject = new BehaviorSubject(routingTreeJson as RoutingTree);
  }

  public get(): PropsInterface {
    return {
      language: this.languageSubject,
      token: this.tokenSubject,
      affiliate: this.affiliateSubject,
      loggedInAffiliate: this.loggedInAffiliateSubject,
      routingTree: this.routingTreeSubject,
    };
  }

  public addStorageListener(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === FrameProps.tokenStorage.tokenKey) {
        const newToken = event.newValue ? new Token(event.newValue as string) : null;

        this.tokenSubject.next(newToken?.raw);
      }

      if (event.key === FrameProps.languageStorage.languageKey) {
        const newLanguage = new Language(JSON.parse(event.newValue as string));

        this.languageSubject.next(newLanguage);
      }

      if (event.key === FrameProps.affiliateStorage.affiliateKey) {
        const newAffiliate = event.newValue ? new Affiliate(JSON.parse(event.newValue as string)) : null;

        this.affiliateSubject.next(newAffiliate);
      }

      if (event.key === FrameProps.loggedInAffiliateStorage.loggedInAffiliateKey) {
        const newLoggedInAffiliate = event.newValue ? new Affiliate(JSON.parse(event.newValue as string)) : null;

        this.loggedInAffiliateSubject.next(newLoggedInAffiliate);
      }
    });
  }
}
