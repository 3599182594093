import { Affiliate, Token } from '@eventim/tixx-component-library/models';
import LegacyLoggedInStorage from '../legacyLoggedInStorage';
import TokenStorage from '../tokenStorage';
import AffiliateStorage from '../affiliateStorage';
import RefreshTokenStorage from '../refreshTokenStorage';
import LoggedInAffiliateStorage from '../loggedInAffiliateStorage';

const tokenStorage: TokenStorage = new TokenStorage();

const affiliateStorage: AffiliateStorage = new AffiliateStorage();

const loggedInAffiliateStorage: LoggedInAffiliateStorage = new LoggedInAffiliateStorage();

const refreshTokenStorage: RefreshTokenStorage = new RefreshTokenStorage();

const legacyLoggedInStorage: LegacyLoggedInStorage = new LegacyLoggedInStorage();

export function getCurrentAuth(): CurrentAuth | null {
  const affiliate = affiliateStorage.get();

  if (affiliate === null) {
    return null;
  }

  const loggedInAffiliate = loggedInAffiliateStorage.get();

  if (loggedInAffiliate === null) {
    return null;
  }

  const token = tokenStorage.get();

  if (token === null) {
    return null;
  }

  const refreshToken = refreshTokenStorage.get();

  if (refreshToken === null) {
    return null;
  }

  return {
    affiliate,
    loggedInAffiliate,
    token,
    refreshToken,
  };
}

export function isLoggedIn(): boolean {
  const currentAuth = getCurrentAuth();

  if (currentAuth === null) {
    return false;
  }

  return currentAuth.token.isTokenValid();
}

export function isLegacyLoggedIn(): boolean {
  return legacyLoggedInStorage.get() ?? false;
}

type CurrentAuth = {
  affiliate: Affiliate;
  loggedInAffiliate: Affiliate;
  token: Token;
  refreshToken: string;
};
