export default class LegacyLoggedInStorage {
  readonly legacyLoggedInKey: string = 'frame_legacyLoggedIn';

  save(isLoggedIn: boolean): boolean {
    sessionStorage.setItem(this.legacyLoggedInKey, String(isLoggedIn));

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.legacyLoggedInKey,
        newValue: String(isLoggedIn),
      }),
    );

    return isLoggedIn;
  }

  get(): boolean | null {
    const legacyLoggedIn = sessionStorage.getItem(this.legacyLoggedInKey);

    if (legacyLoggedIn !== null) {
      return Boolean(legacyLoggedIn);
    }

    return null;
  }

  delete(): void {
    sessionStorage.removeItem(this.legacyLoggedInKey);
  }
}
