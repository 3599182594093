import { Affiliate } from '@eventim/tixx-component-library/models';

export default class AffiliateStorage {
  readonly affiliateKey: string = 'frame_affiliate';

  save(affiliate: Affiliate | string): Affiliate {
    if (typeof affiliate === 'string') {
      // eslint-disable-next-line no-param-reassign
      affiliate = new Affiliate(JSON.parse(affiliate));
    }

    sessionStorage.setItem(this.affiliateKey, JSON.stringify(affiliate));

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.affiliateKey,
        newValue: JSON.stringify(affiliate),
      }),
    );

    return affiliate;
  }

  get(): Affiliate | null {
    const affiliate = sessionStorage.getItem(this.affiliateKey);

    if (affiliate !== null) {
      return new Affiliate(JSON.parse(affiliate));
    }

    return null;
  }

  delete(): void {
    sessionStorage.removeItem(this.affiliateKey);
  }
}
