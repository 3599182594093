export default class RefreshTokenStorage {
  readonly refreshTokenKey: string = 'frame_refreshToken';

  save(refreshToken: string): string {
    sessionStorage.setItem(this.refreshTokenKey, refreshToken);

    dispatchEvent(
      new StorageEvent('storage', {
        key: this.refreshTokenKey,
        newValue: refreshToken,
      }),
    );

    return refreshToken;
  }

  get(): string | null {
    return sessionStorage.getItem(this.refreshTokenKey);
  }

  delete(): void {
    sessionStorage.removeItem(this.refreshTokenKey);
  }
}
