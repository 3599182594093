import { isLegacyLoggedIn, isLoggedIn } from '@/shared/utils/authHelper';
import { navigateToUrl } from 'single-spa';
import StorageVersionStorage from '@/storage/storageVersionStorage';

export default class GlobalGuard {
  static readonly storageVersionStorage: StorageVersionStorage = new StorageVersionStorage();

  private static resetAppStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  private static isAppStorageInvalid(): boolean {
    const currentStorageVersion = import.meta.env.VITE_STORAGE_VERSION;
    const appStorageVersion = GlobalGuard.storageVersionStorage.get();

    return appStorageVersion !== currentStorageVersion;
  }

  private static isAppStorageVersionMissing(): boolean {
    const appStorageVersion = GlobalGuard.storageVersionStorage.get();

    return appStorageVersion === null;
  }

  private static setNewStorageVersion(): void {
    GlobalGuard.storageVersionStorage.save(import.meta.env.VITE_STORAGE_VERSION);
  }

  static handleInvalidAppStorage(): void {
    if (GlobalGuard.isAppStorageInvalid()) {
      if (GlobalGuard.isAppStorageVersionMissing()) {
        GlobalGuard.resetAppStorage();
        GlobalGuard.setNewStorageVersion();

        navigateToUrl(`/login`);
      } else {
        GlobalGuard.resetAppStorage();
        GlobalGuard.setNewStorageVersion();

        navigateToUrl(`/login?newStorageVersion=true`);
      }
    }
  }

  static handleInvalidAuth(): void {
    try {
      if (!isLoggedIn()) {
        if (window.location.pathname === '/') {
          navigateToUrl(`/login`);
          return;
        }

        if (!window.location.pathname.startsWith('/login')) {
          navigateToUrl(`/login?redirect=${window.location.pathname}`);
        }
        return;
      }

      if (!isLegacyLoggedIn()) {
        // If the user is logged in in our new auth system, but not in the legacy system, log them out
        navigateToUrl(`/logout`);
      }
    } catch (error) {
      // If an error occurs on auth check, reset the app state and navigate to the login page
      GlobalGuard.resetAppStorage();
      GlobalGuard.setNewStorageVersion();
      navigateToUrl(`/login`);
      throw error;
    }
  }
}
