/* eslint no-param-reassign: off */
/* eslint func-names: off */
/* eslint no-underscore-dangle: off */

function appendScriptToHead(gtagId: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  document.head.appendChild(script);
}

function executeGoogleAnalyticsScript(gtagId: string) {
  window.dataLayer = window.dataLayer || [];

  function gtag(...args: any) {
    window.dataLayer.push(args);
  }

  gtag('js', new Date());
  gtag('config', gtagId);
}

/*
    This code is a modification from the hotjar documentation
    Usually you see this code placed inside the index.html file
 */
function executeHotjarScript(
  hotjarId: string,
  h: Window,
  o: Document,
  t: string,
  j: string,
  a?: { appendChild: any },
  r?: { async: any; src: any },
) {
  h.hj =
    h.hj ||
    function (...args: any) {
      (h.hj.q = h.hj.q || []).push(args);
    };
  h._hjSettings = { hjid: hotjarId, hjsv: 6 };
  a = o.getElementsByTagName('head')[0] as any;
  r = o.createElement('script') as any;
  if (r) {
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  }
  if (a) {
    a.appendChild(r);
  }
}

export function startGtag() {
  /**
   * Referencing an env variable through `${import.meta.env.VITE_GTAG_ID} prevents inlining because the variable will be populated/substituted with the actual value during runtime.
   */
  const gtagId = `${import.meta.env.VITE_GTAG_ID}`;

  if (gtagId) {
    appendScriptToHead(gtagId);
    executeGoogleAnalyticsScript(gtagId);
  }
}

export function startHotjar() {
  /**
   * Referencing an env variable through `${import.meta.env.VITE_HOTJAR_ID} prevents inlining because the variable will be populated/substituted with the actual value during runtime.
   */
  const hotjarId = `${import.meta.env.VITE_HOTJAR_ID}`;

  if (hotjarId) {
    executeHotjarScript(hotjarId, window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}
